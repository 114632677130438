<template>
  <div>
    <v-app>
      <primary-menu></primary-menu>
      <secundary-menu ></secundary-menu>
      <div style="max-height: 300px; margin-top: 0">
        <v-card elevation="0">
          <v-card-text class="mx-auto m-txt-center">
            <span class="m-size-font-24" style="margin-top: -400px; display: block">404 | Página não encontrada</span>
          </v-card-text>
        </v-card>
      </div>
      <section-copy></section-copy>
    </v-app>
  </div>
</template>

<script>
import PrimaryMenu from "../components/menus/PrimaryMenu";
import SecundaryMenu from "../components/menus/SecundaryMenu";
import SectionCopy from "../components/particular/section/SectionCopy";

export default {
  components: { SectionCopy, SecundaryMenu, PrimaryMenu },
  name: "NotFound",
};
</script>

<style scoped></style>
